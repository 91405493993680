const gaEventLabel = {
    open_product: 'Open_product',
    open_page: 'Open_page',
    searched_product: 'Searched_product',
    add_to_grocery_list: 'Add_to_grocery_list',
    open_product_recipe: 'Open_product_recipe',
    open_product_video: 'Open_product_video',
    open_product_show_price_video: 'Open_product_show_price_video',
    open_product_after_video: 'Open_product_after_video',
    open_product_link: 'Open_product_link',
    open_product_curiosity: 'Open_product_curiosity',
    open_product_specifications: 'Open_product_specifications',
    open_promo: 'Open_promo',
    share_product_facebook: "Share_product_fb",
    share_product_whatsapp: "Share_product_wa",
    share_leaflet_facebook: "Share_leaflet_fb",
    share_leaflet_twitter: "Share_leaflet_tw",
    share_leaflet_telegram: "Share_leaflet_tg",
    share_leaflet_whatsapp: "Share_leaflet_wa",
    share_leaflet_mail: "Share_leaflet_ml",
    page_video_play: "Page_video_play",
    page_video_mandatory_completed: "Page_video_mandatory_completed",
    page_video_completion: "Page_video_completion",
    page_video_closed_by_user: "Page_video_closed_by_user",
    banner_click: "Banner_click"
};

export {
    gaEventLabel
};
