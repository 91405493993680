import React, { Component } from 'react';
import { serverResponse } from '../util/fakeServer';
import ReactGA from 'react-ga';
import { gaEventLabel } from '../settings/gaConfig';
import CustomProduct from '../components/leaflet/CustomProduct';

import "../assets/css/custom.css";

class Pages extends Component {

    state = {
        pages: []
    }

    navMarkers = (newMarker) => {
        //TODO
        //axios per l'incremento del counter di visualizzazioni della modal di tipo newmarker
        /*axios({
            method: 'post',
            url: '',
            headers: {
            },
        })
            .then(res => {
 
            }).catch(error => {
                //console.log(error);
            });*/
        var element = document.getElementById(newMarker + "-" + this.props.info.item_id);
        element.click();
    }

    componentDidMount() {
        let tmpPages = [];
        let tmp = Object.assign({}, serverResponse);
        let products = [];

        tmp.leaflet.pages.forEach((page) => {
            tmpPages.push(page.image_file);
            page.products.forEach(prod => {
                products.push(prod);
            });
        });

        let customproducts = [];
        products.forEach((element, key) => {
            customproducts.push(<CustomProduct key={key} info={element} leafletSwipe={() => { }} isSwipeDisabed={() => { }} setSwipe={() => { }} />);
        });


        this.setState({
            pages: tmpPages,
            products: customproducts
        });

        if (serverResponse.config.ga_active) {
            ReactGA.initialize(serverResponse.config.ga_tracking_id, {
    gaOptions: { cookieFlags: 'max-age=7200;secure;samesite=none' },
  })
            ReactGA.pageview("/" + serverResponse.config.release_id + "/pagine");
        }
    }

    goToPage = (page) => {
        this.props.history.push("/leaflet/" + page);
    }

    render() {

        const {
            pages
        } = this.state;

        const gridImages = pages.map((image, key) => {
            return (
                <div key={key} className="col-4 col-sm-4 col-md-3 col-lg-2 mobilePadding5">
                    <div className="roe-card-style">
                        <img src={process.env.PUBLIC_URL + image + "?v=" + serverResponse.config.version} alt="page" className="image-pages-list" onClick={() => this.goToPage(key + 1)} />
                    </div>
                </div>
            );
        })

        return (
            <div className="row">
                <style>{"\
        .route-height{\
            padding: 10px 20px !important;\
        }\
      "}</style>
                {gridImages}
                <div style={{ display: "none" }}>
                    {this.state.products}
                </div>
            </div>
        );
    }
}

export default Pages;