import React, { Component } from 'react';
import { connect } from 'react-redux';
import CustomCarousel from '../components/leaflet/CustomCarousel';
import { serverResponse } from '../util/fakeServer';
import ReactGA from 'react-ga';
import { gaEventLabel } from '../settings/gaConfig';
import { isMobile } from "react-device-detect";

import { Helmet } from "react-helmet";

class Leaflet extends Component {

  state = {
    leaflet: {},
    setPageIndex: null,
    item_id: null,
    meta_tags: <Helmet></Helmet>
  }

  componentDidMount() {
    if (serverResponse.config.ga_active) {
      ReactGA.initialize(serverResponse.config.ga_tracking_id, {
    gaOptions: { cookieFlags: 'max-age=7200;secure;samesite=none' },
  })
      ReactGA.pageview("/" + serverResponse.config.release_id + "/volantino");
    }
  }

  componentWillMount() {
    //console.log(this.props.match.params);
    let go_to_page = this.props.match.params.index;
    let item_id_get = 0;
    if (this.props.match.path == '/volantino') {
      go_to_page = 0;
    } else {
      go_to_page = this.props.match.params.index;
      if (this.props.match.params.item_id) {
        item_id_get = this.props.match.params.item_id;
        let pages = serverResponse.leaflet.pages.filter(page => page.products.filter(prod => prod.item_id == item_id_get).length > 0);
        if (pages.length > 0) {
          let product = pages[0].products.filter(prod => prod.item_id == item_id_get)[0];
          go_to_page = pages[0].number;
          this.setState({
            meta_tags: (
              <Helmet>
                <meta property="og:title" content={product.description}></meta>
                <meta property="og:url" content={serverResponse.config.shareFlyerURL + "/products/" + product.item_id}></meta>
                <meta property="og:description" content={serverResponse.config.shareProductSentence}></meta>
                <meta property="og:image" content={product.images[0].image_file}></meta>
                <meta property="og:type" content="website"></meta>
              </Helmet>
            )
          });
        }
      } 
    }
    // this.setState({ setPageIndex: this.props.match.params.index })
    this.setState({ setPageIndex: go_to_page })
    this.setState({ item_id: item_id_get })
  }

  render() {

    let tmp = Object.assign({}, serverResponse);

    const {
      setPageIndex
    } = this.state;

    const {
      item_id
    } = this.state;

    return (
      <div>
        {this.state.meta_tags}
        <CustomCarousel leaflet={tmp.leaflet} setPageIndex={setPageIndex} itemId={item_id} />

      </div>
    );

  }
}


const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    LanguageSwitcher: state.LanguageSwitcher,
    locale: state.LanguageSwitcher.language.locale,
    authData: {
      token: state.auth.accessToken,
      isLogin: state.auth.isLogin
    },
    themeSetting: {
      toolbarAlignValue: state.themeSetting.toolbarAlignValue,
      footerAlignValue: state.themeSetting.footerAlignValue,
      sidebarDisplayValue: state.themeSetting.sidebarDisplayValue,
      toolbarDisplayValue: state.themeSetting.toolbarDisplayValue,
      footerDisplayValue: state.themeSetting.footerDisplayValue,
      sidebarTransParentValue: state.themeSetting.sidebarTransParentValue,
      transparentImage: state.themeSetting.transparentImage,
      activeLinkStyle: state.themeSetting.activeLinkStyle,
      sidebarMiniValue: state.themeSetting.sidebarMiniValue,
      sidebarTransParentActiveBack:
        state.themeSetting.sidebarTransParentActiveBack,
      sidebarTransParentActiveColor:
        state.themeSetting.sidebarTransParentActiveColor
    }
  };
};

export default connect(mapStateToProps, null)(Leaflet);