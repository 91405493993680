const leaflets = {
    "bravo": {
        "config": {
            "primary-color": "rgb(9, 163, 229)",
            "secondary-color": "rgb(242, 191, 0)",
            "tertiary-color": "#ffdd00",
            "hover-color": "rgba(220, 35, 17, 0.5)",
            "primary-alpha-color": "rgba(35, 94, 157, 0.7)",
            "clientIcon": require("assets/images/clientLogo.png"),
            "serverPath": "splendidisplendenti.volantinointerattivo.net/",
            "pdfPath": "/media/pdf/volantino.pdf",
            "shareFlyerTitle": "Splendidi e Splendenti | Volantino interattivo",
            "shareFlyerURL": "https://splendidisplendenti.volantinointerattivo.net?v=1",
            "shareProductSentence": "Guarda il Volantino di Splendidi e Splendenti!",
            "ogTitleMeta": "Splendidi e Splendenti | Volantino interattivo",
            "ogDescriptionMeta": "Sfoglia il Volantino Interattivo di Splendidi e Splendenti. Offerte valide dal 14/10 al 27/10",
            "ogImageMeta": "https://splendidisplendenti.volantinointerattivo.net/media/images/demo_21_fb.png",
            "filterBrand": 0,
            "filterUnderprice": 0,
            "brandImage": "",
            "underPriceImage": "",
            "ga_active": false,
            "ga_tracking_id": "UA-152069090-",
            "release_id": "1",
            "send_grocery_list_to_market": false,
            "grocery_list_min_value": 20,
            "client_id": 0,
            "signboard_id": 0,
            "has_pages": true,
            "hide_grocery_list": false,
            "hide_searchbar": false,
            "line_through": false,
            "category_banner": "",
            "category_banner_mobile": "",
            "hide_plus_product": false,
            "hide_plus_product_price": false,
            "type": "leaflet",
            "product_banner": "",
            "href_banner": "",
            "internal_banner_click": false,
            "external_banner_click": false,
            "hide_listPages": false,
            "version": 1
        },
        "leaflet": {
            "name": "Splendidi e Splendenti",
            "code": "1",
            "id_campaign": "1",
            "index": {
                "image_file": "/media/interactive_flyer_19/pages/indice.png",
                "links": [
                    {
                        "page": 2,
                        "blueprint": {
                            "top": 14.136158193308875,
                            "left": 2.827899805017109,
                            "width": 95.00926992977394,
                            "height": 10.89118171224156
                        }
                    },
                    {
                        "page": 4,
                        "blueprint": {
                            "top": 26.17671586124297,
                            "left": 2.827899805017109,
                            "width": 95.00926992977394,
                            "height": 10.89118171224156
                        }
                    },
                    {
                        "page": 6,
                        "blueprint": {
                            "top": 37.96378810458898,
                            "left": 2.6927229134415507,
                            "width": 95.00926992977394,
                            "height": 10.89118171224156
                        }
                    },
                    {
                        "page": 6,
                        "blueprint": {
                            "top": 49.87760306022902,
                            "left": 2.6927229134415507,
                            "width": 95.00926992977394,
                            "height": 10.89118171224156
                        }
                    },
                    {
                        "page": 8,
                        "blueprint": {
                            "top": 61.79141801586907,
                            "left": 2.827899805017109,
                            "width": 95.00926992977394,
                            "height": 10.89118171224156
                        }
                    },
                    {
                        "page": 10,
                        "blueprint": {
                            "top": 73.83197568380318,
                            "left": 2.827899805017109,
                            "width": 95.00926992977394,
                            "height": 10.89118171224156
                        }
                    },
                    {
                        "page": 12,
                        "blueprint": {
                            "top": 85.74579063944321,
                            "left": 2.827899805017109,
                            "width": 95.00926992977394,
                            "height": 10.89118171224156
                        },


                    }
                ]
            },
            "categories": [
                {
                    "category_name": "Igiene Persona",
                    "subcategories": [
                        "Corpo",
                        "Viso",
                        "MakeUp",
                        "Capelli",
                        "Intima",
                        "Orale"
                    ]
                },
                {
                    "category_name": "Igiene Casa",
                    "subcategories": [
                        "Extra",
                        "Bucato",
                        "Pavimenti",
                        "Superfici"
                    ]
                },
                {
                    "category_name": "No Food",
                    "subcategories": [
                        "Home"
                    ]
                },
                {
                    "category_name": "Pet",
                    "subcategories": [
                        "Food"
                    ]
                },
                {
                    "category_name": "Alimentari",
                    "subcategories": [
                        "Pasta",
                        "Dispensa"
                    ]
                },
                {
                    "category_name": "Medicinali",
                    "subcategories": [
                        "Uso Esterno"
                    ]
                }
            ],
            "pages": [
                {
                    "number": 1,
                    "image_file": "/media/interactive_flyer_19/pages/page_1.jpg",
                    "products": [
                        {
                            "item_id": 705384,
                            "field1": "splendidi e splendenti",
                            "field2": "sapone liquido",
                            "field3": "crema latte/igienizzante/talco",
                            "field4": "750 ml",
                            "description": "splendidi e splendenti sapone liquido crema latte/igienizzante/talco 750 ml",
                            "grammage": 750,
                            "price": "1.50",
                            "price_label": "€ 1,50",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Crema latte",
                                "Igienizzante",
                                "Talco"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Corpo",
                            "blueprint": {
                                "top": 30.096014492753632,
                                "left": 0.0,
                                "width": 100.0,
                                "height": 61.54710144927537
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8018700036160_1554890675.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8018700036177_1554890676.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8018700036719_1554890677.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_MF09Bgl.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                {
                                    "type": "play",
                                    "title": "VIDEO - Tutorial Splendidi e Splendenti",
                                    "data": "/media/interactive_flyer_19/video/tutorial.mp4"
                                },
                                {
                                    "type": "world",
                                    "title": "LINK - Sito web",
                                    "data": "https://www.splendidisplendenti.it/spesa-consegna-domicilio/89128"
                                }


                            ],
                            "related": [705329, 705390, 705470]
                        },

                    ]
                },
                {
                    "number": 2,
                    "image_file": "/media/interactive_flyer_19/pages/page_2.jpg",
                    "products": [
                        {
                            "item_id": 705329,
                            "field1": "splendidi e splendenti",
                            "field2": "catturacolore",
                            "field3": "12 fogli",
                            "field4": "",
                            "description": "splendidi e splendenti catturacolore 12 fogli",
                            "grammage": 0,
                            "price": "1.30",
                            "price_label": "€ 1,30",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Extra",
                            "blueprint": {
                                "top": 7.984213250517603,
                                "left": 0.0,
                                "width": 32.56913650665145,
                                "height": 27.261387163561075
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_zKn5Z2M_DLv3CRe.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705328,
                            "field1": "dr. beckmann ",
                            "field2": "additivo cura lavatrice ",
                            "field3": "",
                            "field4": "50 lavaggi - 200 ml",
                            "description": "dr. beckmann  additivo cura lavatrice  50 lavaggi - 200 ml",
                            "grammage": 200,
                            "price": "3.99",
                            "price_label": "€ 3,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Bucato",
                            "blueprint": {
                                "top": 7.984213250517603,
                                "left": 32.72185320797071,
                                "width": 26.740142412923873,
                                "height": 27.261387163561075
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/4008455404516_1554884466.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_DBeSHn4_9K9vOek.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "LINK - Sito web",
                                    "data": "https://www.dr-beckmann.it/trattamento-lavatrice/per-lavatrici/il-cura-lavatrice/"
                                },
                                {
                                    "type": "info",
                                    "title": "GUIDA - Cura Lavatrice",
                                    "data": {

                                        "titolo": "GUIDA - Cura Lavatrice",
                                        "testo": "1. Pulire il filtro dalla lanuggine. Agitare bene la bottigila prima dell’uso. Estrarre la vaschetta del detersivo e immergerla per 30 minuti in una soluzione di 50ml di prodotto diluiti  in 4 litri di acqua tiepida (guardare la tacca di misurazione sul retro dell’etichetta del flacone). Pulire il dispensatore da eventuali residui e reinserirlo nella macchina. 2. Rimuovere sporco e residui dalle guarnizioni di gomma del cestello. Versare una piccola quantità di prodotto su un panno e strofinare il vetro dell’oblò e la guarnizione di gomma del cestello. Per una corretta manutenzione, lasciare agire per pochi minuti e pulire con un panno umido. 3. Versare il restante contenuto del flacone nella vaschetta del detersivo e avviare un normale ciclo di lavaggio (almeno a 60°) senza il ciclo di pre-lavaggio, senza aggiungere detersivo e senza bucato.",
                                        "img": [
                                            "/media/interactive_flyer_19/images/cura_lavatrice.jpg"
                                        ]
                                    }
                                },

                            ]
                        },
                        {
                            "item_id": 705369,
                            "field1": "splendidi e splendenti",
                            "field2": "vassoi alluminio",
                            "field3": "1 porzione - 4 pz",
                            "field4": "",
                            "description": "splendidi e splendenti vassoi alluminio 1 porzione - 4 pz",
                            "grammage": 0,
                            "price": "0.80",
                            "price_label": "€ 0,80",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Extra",
                            "blueprint": {
                                "top": 0.0,
                                "left": 59.747189460707645,
                                "width": 40.25281053929235,
                                "height": 22.41666666666666
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8021719141299_1554887252_1555063520.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_qVcfh33_7aaBYUK.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705350,
                            "field1": "splendidi e splendenti",
                            "field2": "panno microfibra multiuso",
                            "field3": "",
                            "field4": "35 x 35 cm",
                            "description": "splendidi e splendenti panno microfibra multiuso 35 x 35 cm",
                            "grammage": 0,
                            "price": "0.99",
                            "price_label": "€ 0,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "No Food",
                            "subcategory": "Home",
                            "blueprint": {
                                "top": 22.608695652173914,
                                "left": 59.747189460707645,
                                "width": 40.25281053929235,
                                "height": 32.72722567287785
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/920896_splendidi_e_splendenti_panno_microfibra_multiuso_1569921180.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_BEzRHJl_zbp1Grv.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705353,
                            "field1": "gamex dust",
                            "field2": "panno cattura polvere 3d",
                            "field3": "",
                            "field4": "22x28 cm - x 20",
                            "description": "gamex dust panno cattura polvere 3d 22x28 cm - x 20",
                            "grammage": 0,
                            "price": "1.99",
                            "price_label": "€ 1,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Extra",
                            "blueprint": {
                                "top": 35.27950310559007,
                                "left": 0.13247713849380854,
                                "width": 20.778671180702492,
                                "height": 20.056418219461698
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_ooo4rDP_Qsb8O4W.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705354,
                            "field1": "arix",
                            "field2": "strofi panno pavimenti",
                            "field3": "",
                            "field4": "cm 50 x 60",
                            "description": "arix strofi panno pavimenti cm 50 x 60",
                            "grammage": 0,
                            "price": "2.99",
                            "price_label": "€ 2,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Extra",
                            "blueprint": {
                                "top": 35.1552795031056,
                                "left": 21.19634215900937,
                                "width": 19.18894551877679,
                                "height": 20.180641821946168
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8008990006110_arix_strofi_x1_1569920345.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_8wmNJ2A_w4d5hEa.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705372,
                            "field1": "splendidi e splendenti",
                            "field2": "pinze in plastica",
                            "field3": "",
                            "field4": "10 pz",
                            "description": "splendidi e splendenti pinze in plastica 10 pz",
                            "grammage": null,
                            "price": "0.90",
                            "price_label": "€ 0,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "No Food",
                            "subcategory": "Home",
                            "blueprint": {
                                "top": 35.1552795031056,
                                "left": 40.40552724061161,
                                "width": 19.18894551877679,
                                "height": 20.180641821946168
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8053629801031_1554888045.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_a313hrH_MvZnrlf.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705360,
                            "field1": "fatigati freschissimo",
                            "field2": "deodorante per ambiente spray",
                            "field3": "aria di primavera",
                            "field4": "250 ml",
                            "description": "fatigati freschissimo deodorante per ambiente spray aria di primavera 250 ml",
                            "grammage": 250,
                            "price": "3.49",
                            "price_label": "€ 3,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Extra",
                            "blueprint": {
                                "top": 55.52795031055901,
                                "left": 0.0,
                                "width": 20.778671180702492,
                                "height": 20.55331262939959
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/freschissimo_deodorante_primavera_1556529599.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_ZrMl6lM_BvoRhiy.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705375,
                            "field1": "fatigati",
                            "field2": "colla forza 10 blister ",
                            "field3": "",
                            "field4": "3 gr",
                            "description": "fatigati colla forza 10 blister  3 gr",
                            "grammage": null,
                            "price": "0.99",
                            "price_label": "€ 0,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "No Food",
                            "subcategory": "Home",
                            "blueprint": {
                                "top": 55.27950310559007,
                                "left": 20.93138788202175,
                                "width": 19.321422657270595,
                                "height": 20.925983436853
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8011690046577_1555680195.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_tjy7uZK_znmAuEY.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705358,
                            "field1": "splendidi e splendenti",
                            "field2": "scopa bassa",
                            "field3": "per tutti i pavimenti",
                            "field4": "",
                            "description": "splendidi e splendenti scopa bassa per tutti i pavimenti",
                            "grammage": null,
                            "price": "1.50",
                            "price_label": "€ 1,50",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Pavimenti",
                            "blueprint": {
                                "top": 55.27950310559007,
                                "left": 40.14057296362399,
                                "width": 19.321422657270595,
                                "height": 20.925983436853
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8002120120356_1554885180.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8002120120356bis_1554885181.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_02vTAr0_fOFfP1Z.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705370,
                            "field1": "splendidi e splendenti",
                            "field2": "sacco gelo",
                            "field3": "piccolo/medio/grande",
                            "field4": "",
                            "description": "splendidi e splendenti sacco gelo piccolo/medio/grande",
                            "grammage": null,
                            "price": "0.90",
                            "price_label": "€ 0,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": ["Piccolo", "Medio", "Grande"],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "No Food",
                            "subcategory": "Home",
                            "blueprint": {
                                "top": 55.40372670807454,
                                "left": 59.48223518372004,
                                "width": 19.321422657270595,
                                "height": 20.925983436853
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/spl_sacco_gelo_grande_1555063541.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/spl_sacco_gelo_medio_1555063542.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/spl_sacco_gelo_piccolo_1555063543.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_nbzSh0G_dtQPPbY.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705323,
                            "field1": "nicky",
                            "field2": "milleusi ",
                            "field3": "310 strappi",
                            "field4": "1 rotolo",
                            "description": "nicky milleusi  310 strappi 1 rotolo",
                            "grammage": 0,
                            "price": "2.49",
                            "price_label": "€ 2,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Superfici",
                            "blueprint": {
                                "top": 55.40372670807454,
                                "left": 78.95637454230989,
                                "width": 21.04362545769011,
                                "height": 20.925983436853
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8004260450744_1555675785.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_jsD4UDb_HKKY6hx.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 708888,
                            "field1": "morando miglior gatto",
                            "field2": "straccetti",
                            "field3": "vari tipi",
                            "field4": "100 gr",
                            "description": "morando miglior gatto straccetti vari tipi 100 gr",
                            "grammage": 100,
                            "price": "0.59",
                            "price_label": "€ 0,59",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Pet",
                            "subcategory": "Food",
                            "blueprint": {
                                "top": 76.5217391304348,
                                "left": 0.0,
                                "width": 25.81280244346722,
                                "height": 19.06262939958592
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/straccetti-mazo-carote_1504630456_1558603330_1560880060.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/straccetti-salmone-tonno_1504630456_1558603330_1560880060.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_SBPh29r_GDJcPEv.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                {
                                    "type": "play",
                                    "title": "VIDEO - Spot",
                                    "data": "/media/interactive_flyer_19/video/migliorgatto.mp4"
                                },
                            ],
                            "related": [708772, 708889, 708890]
                        },
                        {
                            "item_id": 708772,
                            "field1": "morando miglior cane",
                            "field2": "unico 100% patè",
                            "field3": "vari tipi",
                            "field4": "100 gr",
                            "description": "morando miglior cane unico 100% patè vari tipi 100 gr",
                            "grammage": 100,
                            "price": "0.99",
                            "price_label": "€ 0,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Pet",
                            "subcategory": "Food",
                            "blueprint": {
                                "top": 76.39751552795032,
                                "left": 25.70056486779886,
                                "width": 24.223076781541515,
                                "height": 19.06262939958592
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/MC_Unico_Prosciutto_1482403557_1537787637_1547565684_15476_V8eHEGa.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/MCUnico_Tacchino_1482403557_1537787637_1565345305.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/MCUnico_Vitello_1482403558_1537787638_1565345305.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_N6Tivra_ilQAlJC.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 708889,
                            "field1": "minerva",
                            "field2": "pet panno",
                            "field3": "in microfibra con tasca",
                            "field4": "35x75 cm",
                            "description": "minerva pet panno in microfibra con tasca 35x75 cm",
                            "grammage": 0,
                            "price": "3.99",
                            "price_label": "€ 3,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "No Food",
                            "subcategory": "Home",
                            "blueprint": {
                                "top": 76.39751552795032,
                                "left": 50.20883548915344,
                                "width": 23.825645366060087,
                                "height": 19.06262939958592
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_nGnohX4_mtYaMdL.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 708890,
                            "field1": "minerva",
                            "field2": "pet panno",
                            "field3": "in microfibra con tasca",
                            "field4": "45x90 cm",
                            "description": "minerva pet panno in microfibra con tasca 45x90 cm",
                            "grammage": 0,
                            "price": "4.99",
                            "price_label": "€ 4,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "No Food",
                            "subcategory": "Home",
                            "blueprint": {
                                "top": 76.39751552795032,
                                "left": 74.31967469502659,
                                "width": 25.680325304973408,
                                "height": 21.050207039337472
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_BUGERqc_oYZUZHn.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 3,
                    "image_file": "/media/interactive_flyer_19/pages/page_3.jpg",
                    "products": [
                        {
                            "item_id": 705478,
                            "field1": "clendy",
                            "field2": "salviettine",
                            "field3": "struccanti/multiuso/igienizzanti",
                            "field4": "15 pz",
                            "description": "clendy salviettine struccanti/multiuso/igienizzanti 15 pz",
                            "grammage": 0,
                            "price": "0.79",
                            "price_label": "€ 0,79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Struccanti",
                                "Multiuso",
                                "Igienizzanti"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Corpo",
                            "blueprint": {
                                "top": 0.2484472049689441,
                                "left": 0.0,
                                "width": 21.04362545769011,
                                "height": 22.292443064182198
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/59759_1547637189_1569923403.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/SALVIETTE-IGIENIZZANTI-RINFRESCANTI-ALLA-MENTA-15-PZ_1569923513.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_hN1Z7mN_bSZANgI.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705477,
                            "field1": "fresh & clean ",
                            "field2": "salviettine milleusi disinfettanti",
                            "field3": "x 20",
                            "field4": "",
                            "description": "fresh & clean  salviettine milleusi disinfettanti x 20",
                            "grammage": 0,
                            "price": "1.99",
                            "price_label": "€ 1,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Corpo",
                            "blueprint": {
                                "top": 0.12422360248447205,
                                "left": 21.19634215900937,
                                "width": 18.923991241789174,
                                "height": 22.292443064182198
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8002340006225_1536249169_1569916347.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_C4N2nk7_PWG0e0A.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705316,
                            "field1": "clinians",
                            "field2": "gel detergente viso",
                            "field3": "esfoliante",
                            "field4": "150 ml",
                            "description": "clinians gel detergente viso esfoliante 150 ml",
                            "grammage": 150,
                            "price": "3.60",
                            "price_label": "€ 3,60",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Viso",
                            "blueprint": {
                                "top": 22.608695652173914,
                                "left": 0.0,
                                "width": 40.25281053929235,
                                "height": 32.60300207039338
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8003510024643_clinians_gel_detergente_esfoliante_1569920811.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_cyD4C7b_1BrvBPh.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705467,
                            "field1": "clinians",
                            "field2": "gel detergente rinfrescante",
                            "field3": "hydra plus",
                            "field4": "150 ml",
                            "description": "clinians gel detergente rinfrescante hydra plus 150 ml",
                            "grammage": 150,
                            "price": "2.59",
                            "price_label": "€ 2,59",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Viso",
                            "blueprint": {
                                "top": 8.074534161490684,
                                "left": 40.538004379105416,
                                "width": 29.522162321293855,
                                "height": 27.137163561076605
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/HydraPlusGel150_8003510020096_1536248575_1559123689_1564494594.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_AxjlVHz_q4jrvKA.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705468,
                            "field1": "clinians",
                            "field2": "acqua micellare",
                            "field3": "antistress/purificante",
                            "field4": "400 ml",
                            "description": "clinians acqua micellare antistress/purificante 400 ml",
                            "grammage": 400,
                            "price": "3.98",
                            "price_label": "€ 3,98",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Antistress",
                                "Purificante"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Viso",
                            "blueprint": {
                                "top": 7.826086956521739,
                                "left": 70.21288340171851,
                                "width": 29.522162321293855,
                                "height": 27.137163561076605
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8003510030491_1554885389.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/CliniansHydraPlusAcquaMicellareAttiva_8003510030316_153624_YU6vA4Z.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_lZriuSJ_q1UjfG3.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705381,
                            "field1": "venus",
                            "field2": "sapone liquido mani viso",
                            "field3": "antibatterico/neutro/sensitive",
                            "field4": "250 ml",
                            "description": "venus sapone liquido mani viso antibatterico/neutro/sensitive 250 ml",
                            "grammage": 250,
                            "price": "1.00",
                            "price_label": "€ 1,00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Antibatterico",
                                "Neutro",
                                "Sensitive"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Corpo",
                            "blueprint": {
                                "top": 35.40372670807454,
                                "left": 40.538004379105416,
                                "width": 19.321422657270595,
                                "height": 20.056418219461698
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8009150105667_VenusSaponeLiquidoMentolypton250_1553795286.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/vens_sapone_liquido_sensitive_250_ml_1462983344_1542821296_ldJsVwA.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/venus_sapone_liquido_neutro_250_ml_1462983357_1542821296_1_iIM9iqg.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_xScRkU1_z7B5wI1.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705384,
                            "field1": "splendidi e splendenti",
                            "field2": "sapone liquido",
                            "field3": "crema latte/igienizzante/talco",
                            "field4": "750 ml",
                            "description": "splendidi e splendenti sapone liquido crema latte/igienizzante/talco 750 ml",
                            "grammage": 750,
                            "price": "1.50",
                            "price_label": "€ 1,50",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Crema latte",
                                "Igienizzante",
                                "Talco"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Corpo",
                            "blueprint": {
                                "top": 35.27950310559007,
                                "left": 59.747189460707645,
                                "width": 19.321422657270595,
                                "height": 20.056418219461698
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8018700036160_1554890675_aO6UtBN.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8018700036177_1554890676_oupg8c3.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8018700036719_1554890677_UZ5IIE0.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_uXp51IN_ucdNLOo.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705389,
                            "field1": "erbaviva",
                            "field2": "bagnoschiuma",
                            "field3": "vari tipi",
                            "field4": "500 ml",
                            "description": "erbaviva bagnoschiuma vari tipi 500 ml",
                            "grammage": 500,
                            "price": "1.49",
                            "price_label": "€ 1,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Corpo",
                            "blueprint": {
                                "top": 35.40372670807454,
                                "left": 79.22132881929751,
                                "width": 20.778671180702492,
                                "height": 20.056418219461698
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/principale_8004395100002_1558598835_1564471440.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/principale_8004395100095_1558598836_1564471440.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_AyrpNKU_NZYwl9o.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705390,
                            "field1": "splendidi e splendenti",
                            "field2": "bagnodoccia cremoso",
                            "field3": "varie profumazioni",
                            "field4": "750 ml",
                            "description": "splendidi e splendenti bagnodoccia cremoso varie profumazioni 750 ml",
                            "grammage": 750,
                            "price": "1.40",
                            "price_label": "€ 1,40",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Corpo",
                            "blueprint": {
                                "top": 55.40372670807454,
                                "left": 0.0,
                                "width": 20.778671180702492,
                                "height": 20.925983436853
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8018700036122_1554890551.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8018700036139_1554890597.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8018700036146_1554890619.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8018700036153_1554890643.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_nI4enC0_EDNMQei.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705392,
                            "field1": "johnson's",
                            "field2": "bagnoschiuma",
                            "field3": "vari tipi",
                            "field4": "750 ml",
                            "description": "johnson's bagnoschiuma vari tipi 750 ml",
                            "grammage": 750,
                            "price": "2.59",
                            "price_label": "€ 2,59",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Corpo",
                            "blueprint": {
                                "top": 55.52795031055902,
                                "left": 21.19634215900937,
                                "width": 19.05646838028298,
                                "height": 20.925983436853
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/Johnsons_Vita-Rich_Bagno_Schiuma_Rigenerante_Lampone_750_m_XSpMBFV.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/Johnsons_Vita-Rich_Bagno_Schiuma_Vellutante_Papaya_750_ml__r6tN4hR.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/Johnsons_Vita-Rich_smoothies_Bagno_Schiuma_Rilassante_con__Xc0Qzmm.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_pSMtaxI_yWnyKBs.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705380,
                            "field1": "spuma sciampagna",
                            "field2": "sapone profumato",
                            "field3": "argan/rosa e vaniglia",
                            "field4": "90 gr",
                            "description": "spuma sciampagna sapone profumato argan/rosa e vaniglia 90 gr",
                            "grammage": 90,
                            "price": "0.99",
                            "price_label": "€ 0,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Argan",
                                "Rosa e Vaniglia"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Corpo",
                            "blueprint": {
                                "top": 55.52795031055902,
                                "left": 40.80295865609303,
                                "width": 19.05646838028298,
                                "height": 20.925983436853
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8007750010428_spuma_di_sciampagna_sapone_argan_e_patchouli_PyQgug6.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/SAPONETTE_SPUMA_SCIAMPAGNA_ROSA-VANIGLIA_GR.90_1569922227.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_5t1S0AB_5gZqXex.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705413,
                            "field1": "cotoneve",
                            "field2": "spugna bagno",
                            "field3": "soft relax",
                            "field4": "",
                            "description": "cotoneve spugna bagno soft relax",
                            "grammage": 0,
                            "price": "1.99",
                            "price_label": "€ 1,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "No Food",
                            "subcategory": "Home",
                            "blueprint": {
                                "top": 55.40372670807454,
                                "left": 59.61471232221385,
                                "width": 19.05646838028298,
                                "height": 20.925983436853
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/SPUGNA_BAGNO_COTONEVE_SOFT_RELAX_1569922630.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_k4IfkDX_mFL8835.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705314,
                            "field1": "lycia",
                            "field2": "dischetti viso struccanti",
                            "field3": "80 pz",
                            "field4": "",
                            "description": "lycia dischetti viso struccanti 80 pz",
                            "grammage": 0,
                            "price": "1.45",
                            "price_label": "€ 1,45",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "MakeUp",
                            "blueprint": {
                                "top": 55.40372670807454,
                                "left": 78.95637454230989,
                                "width": 21.04362545769011,
                                "height": 20.925983436853
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/LYCIA_DISCHETTI_STRUCCANTI_PZ.80_1560777782_1560864606_1560928638.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_SYYZDGH_Bvlo87W.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705396,
                            "field1": "erbaviva",
                            "field2": "shampoo",
                            "field3": "vari trattamenti",
                            "field4": "500 ml",
                            "description": "erbaviva shampoo vari trattamenti 500 ml",
                            "grammage": 500,
                            "price": "1.49",
                            "price_label": "€ 1,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Capelli",
                            "blueprint": {
                                "top": 76.39751552795032,
                                "left": 0.0,
                                "width": 21.04362545769011,
                                "height": 20.925983436853
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/58191_erbaviva_1532010726_1560870092.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/58192_erbaviva_1532010727_1560870093.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/58194_erbaviva_1532010728_1560870094.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_0Y6Woqa_xQIWQGS.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705397,
                            "field1": "mantovani",
                            "field2": "shampoo",
                            "field3": "lisci/ricci/normali",
                            "field4": "400 ml",
                            "description": "mantovani shampoo lisci/ricci/normali 400 ml",
                            "grammage": 400,
                            "price": "1.89",
                            "price_label": "€ 1,89",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Lisci",
                                "Ricci",
                                "Normali"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Capelli",
                            "blueprint": {
                                "top": 76.27329192546584,
                                "left": 21.19634215900937,
                                "width": 19.18894551877679,
                                "height": 20.925983436853
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8002340008540_mantonavi_shampo_neutro_naturali_1559668794.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/principale_8002340008557_1569922426.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_H4T6Zvn_ADq6w2W.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705470,
                            "field1": "splendidi e splendenti",
                            "field2": "gel",
                            "field3": "vari tipi",
                            "field4": "500 gr",
                            "description": "splendidi e splendenti gel vari tipi 500 gr",
                            "grammage": 500,
                            "price": "3.99",
                            "price_label": "€ 3,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Capelli",
                            "blueprint": {
                                "top": 76.5217391304348,
                                "left": 40.538004379105416,
                                "width": 19.18894551877679,
                                "height": 18.93840579710145
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/spl_fixgel_extra_forte_cocco_1555056493.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/spl_fixgel_extra_forte_1555056494.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/spl_fixgel_iper_forte_1555056495.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_mrdZ9Sj_D3FDzDN.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705479,
                            "field1": "fazzolettini",
                            "field2": "4 veli",
                            "field3": "18 pz",
                            "field4": "gormiti/hutik/flinstones/winx",
                            "description": "fazzolettini 4 veli 18 pz gormiti/hutik/flinstones/winx",
                            "grammage": null,
                            "price": "0.99",
                            "price_label": "€ 0,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Gormiti",
                                "Hutik",
                                "Flinstones",
                                "Winx"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "No Food",
                            "subcategory": "Home",
                            "blueprint": {
                                "top": 76.5217391304348,
                                "left": 59.747189460707645,
                                "width": 19.18894551877679,
                                "height": 18.93840579710145
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/FAZZOLETTINI_GORMITI_4VELI_PZ.18_1564473347.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_oXY005p_Jti4rlh.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705425,
                            "field1": "lines lady",
                            "field2": "assorbente",
                            "field3": "anatomico",
                            "field4": "9 pz",
                            "description": "lines lady assorbente anatomico 9 pz",
                            "grammage": null,
                            "price": "0.89",
                            "price_label": "€ 0,89",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Intima",
                            "blueprint": {
                                "top": 76.77018633540374,
                                "left": 78.95637454230989,
                                "width": 21.04362545769011,
                                "height": 18.93840579710145
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8001480054851_1555675097.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_Y1vyzfj_5mwMjKI.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 4,
                    "image_file": "/media/interactive_flyer_19/pages/page_4.jpg",
                    "products": [
                        {
                            "item_id": 705438,
                            "field1": "oral b",
                            "field2": "dentifricio pro-repair",
                            "field3": "vari tipi",
                            "field4": "ml 75",
                            "description": "oral b dentifricio pro-repair vari tipi ml 75",
                            "grammage": 75,
                            "price": "3.99",
                            "price_label": "€ 3,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Orale",
                            "blueprint": {
                                "top": 19.378881987577643,
                                "left": 0.0,
                                "width": 31.50931939870099,
                                "height": 24.031573498964807
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/oral_b_gengive_smalto_pro_repair_classico_1556802360_15623_jcSzG7y.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/oral_b_gengive_smalto_pro_repair_sbiancante_delicato_15568_rhGXDdw.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_hLd1y0P_kNBZv9T.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705437,
                            "field1": "colgate",
                            "field2": "dentifricio",
                            "field3": "baking soda/fresh gel",
                            "field4": "75 ml",
                            "description": "colgate dentifricio baking soda/fresh gel 75 ml",
                            "grammage": 75,
                            "price": "0.69",
                            "price_label": "€ 0,69",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Baking soda",
                                "Fresh gel"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Orale",
                            "blueprint": {
                                "top": 19.1304347826087,
                                "left": 33.64919317742737,
                                "width": 31.50931939870099,
                                "height": 24.031573498964807
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/colgate_baking_soda_75_ml_1565098474_1569922950.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/colgate_fresh_gel_75_ml_1565098476_1569922951.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_wAx5Nwq_752s9YI.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705434,
                            "field1": "aquafresh",
                            "field2": "dentrifico",
                            "field3": "junior 6+ anni",
                            "field4": "75 ml",
                            "description": "aquafresh dentrifico junior 6+ anni 75 ml",
                            "grammage": 75,
                            "price": "0.99",
                            "price_label": "€ 0,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Orale",
                            "blueprint": {
                                "top": 19.503105590062113,
                                "left": 68.49068060129902,
                                "width": 31.50931939870099,
                                "height": 24.031573498964807
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8016825673239_1544871692_1563284889.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_lCDVrLj_2UAWbZW.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705436,
                            "field1": "pearl drops  ",
                            "field2": "dentifricio 4d",
                            "field3": "vari tipi",
                            "field4": "50 ml",
                            "description": "pearl drops   dentifricio 4d vari tipi 50 ml",
                            "grammage": 50,
                            "price": "2.49",
                            "price_label": "€ 2,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Orale",
                            "blueprint": {
                                "top": 42.857142857142854,
                                "left": 0.0,
                                "width": 39.06051629284807,
                                "height": 26.764492753623188
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/5010724528990_1557245625.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/5010724530009_1557245626.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/5010724530160_1557245627.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_rd3E9ng_PfttfBU.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705443,
                            "field1": "oral-b ",
                            "field2": "collutorio pro expert",
                            "field3": "500 ml",
                            "field4": "",
                            "description": "oral-b  collutorio pro expert 500 ml",
                            "grammage": 500,
                            "price": "2.59",
                            "price_label": "€ 2,59",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Orale",
                            "blueprint": {
                                "top": 42.98136645962733,
                                "left": 39.478187271154944,
                                "width": 22.89830539660343,
                                "height": 26.764492753623188
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/4015600573034_oral_b_pro_expert_collutorio_1559667403.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_83mRaNN_81vfyxn.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705444,
                            "field1": "listerine",
                            "field2": "collutorio",
                            "field3": "vari tipi",
                            "field4": "400/500 ml",
                            "description": "listerine collutorio vari tipi 400/500 ml",
                            "grammage": 0,
                            "price": "3.99",
                            "price_label": "€ 3,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Orale",
                            "blueprint": {
                                "top": 43.975155279503106,
                                "left": 62.794163646065236,
                                "width": 37.20583635393475,
                                "height": 26.764492753623188
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/listerin_coll_1523517650_1560874524.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/listerine_protezione_anti_carie_1524038557_1560874525.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_u7wrC6s_cbNrhHz.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "LINK - Sito web",
                                    "data": "https://www.listerine.it"
                                }
                            ]
                        },
                        {
                            "item_id": 705442,
                            "field1": "aquafresh",
                            "field2": "spazzolino flex dinamic",
                            "field3": "duro/medio",
                            "field4": "",
                            "description": "aquafresh spazzolino flex dinamic duro/medio",
                            "grammage": 0,
                            "price": "0.99",
                            "price_label": "€ 0,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Duro",
                                "Medio"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Orale",
                            "blueprint": {
                                "top": 70.12991718426501,
                                "left": 0.0,
                                "width": 26.342710997442452,
                                "height": 25.522256728778466
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/AQ_FLEX_DINAMIC_DURO_1444643883_1485191805_1564493992.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/AQ_FLEX_DINAMIC_medium_1444643891_1485191807_1564493992.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_45N8e8f_VbJDoKA.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705440,
                            "field1": "oral b",
                            "field2": "spazzolino classic care",
                            "field3": "",
                            "field4": "",
                            "description": "oral b spazzolino classic care",
                            "grammage": 0,
                            "price": "0.95",
                            "price_label": "€ 0,95",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Orale",
                            "blueprint": {
                                "top": 70.12991718426501,
                                "left": 25.568087729305045,
                                "width": 19.321422657270595,
                                "height": 25.522256728778466
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/Oral_b_classic_care_spazzolino_1569916832.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_ZiSGCcj_MWVNNZ5.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705485,
                            "field1": "fatigati",
                            "field2": "spazzolino carbon plus medio",
                            "field3": "",
                            "field4": "2 pz",
                            "description": "fatigati spazzolino carbon plus medio 2 pz",
                            "grammage": 0,
                            "price": "1.99",
                            "price_label": "€ 1,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Orale",
                            "blueprint": {
                                "top": 70.12991718426501,
                                "left": 46.234521334339185,
                                "width": 25.680325304973408,
                                "height": 25.522256728778466
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_C8zyRup_hKAcdv3.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705441,
                            "field1": "oral-b",
                            "field2": "spazzolino classic care",
                            "field3": "40 medio",
                            "field4": "3 pz",
                            "description": "oral-b spazzolino classic care 40 medio 3 pz",
                            "grammage": null,
                            "price": "2.69",
                            "price_label": "€ 2,69",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Orale",
                            "blueprint": {
                                "top": 70.12991718426501,
                                "left": 72.06756334063185,
                                "width": 25.680325304973408,
                                "height": 25.522256728778466
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/3014260762797_oral_b_spazzolino_3_pezzi_1559666936.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_RASYZx6_z2HwxrI.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 5,
                    "image_file": "/media/interactive_flyer_19/pages/page_5.jpg",
                    "products": [
                        {
                            "item_id": 705327,
                            "field1": "dash",
                            "field2": "simply",
                            "field3": "polvere lavatrice",
                            "field4": "73 misurini",
                            "description": "dash simply polvere lavatrice 73 misurini",
                            "grammage": null,
                            "price": "6.99",
                            "price_label": "€ 6,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Bucato",
                            "blueprint": {
                                "top": 0.0,
                                "left": 0.0,
                                "width": 21.04362545769011,
                                "height": 22.665113871635608
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/secondaria_8001841143309_1560866152.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_20TNIUr_fEuviBA.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705334,
                            "field1": "ace gentile",
                            "field2": "candeggina  ",
                            "field3": "classica/profumata",
                            "field4": "2 lt",
                            "description": "ace gentile candeggina   classica/profumata 2 lt",
                            "grammage": 2000,
                            "price": "2.49",
                            "price_label": "€ 2,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Classica ",
                                "Profumata"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Bucato",
                            "blueprint": {
                                "top": 0.0,
                                "left": 21.19634215900937,
                                "width": 19.18894551877679,
                                "height": 22.665113871635608
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8001480021136_1555671005.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8001480021143_1555671006.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_2pdno3l_TNyyvB9.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705325,
                            "field1": "omino bianco",
                            "field2": "lavatrice ",
                            "field3": "igienizzante/muschio bianco",
                            "field4": "23+3 lavaggi",
                            "description": "omino bianco lavatrice  igienizzante/muschio bianco 23+3 lavaggi",
                            "grammage": 0,
                            "price": "2.19",
                            "price_label": "€ 2,19",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Igienizzante",
                                "Muschio bianco"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Bucato",
                            "blueprint": {
                                "top": 22.857142857142858,
                                "left": 0.0,
                                "width": 40.25281053929235,
                                "height": 32.35455486542443
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8003650015402_omino_bianco_detersivo_igienizzante_23e3_1569920789.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8003650015518_omino_bianco_detersivo_muschio_23e3_1569920790.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_qCoUyH6_00uql5W.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705332,
                            "field1": "vernel",
                            "field2": "ammorbidente",
                            "field3": "vari tipi",
                            "field4": "3 lt",
                            "description": "vernel ammorbidente vari tipi 3 lt",
                            "grammage": 3000,
                            "price": "1.79",
                            "price_label": "€ 1,79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Bucato",
                            "blueprint": {
                                "top": 7.950310559006211,
                                "left": 40.40552724061161,
                                "width": 30.581979429244324,
                                "height": 27.261387163561075
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8015100564477_AMMORB.VERNEL_BLU_CLASSICO_LT.3_1561995620.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8015100564484_AMMORB.VERNEL_LAVANDA_LT.3_1561995620.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_gbD5oJN_b52Srsq.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705326,
                            "field1": "bio presto",
                            "field2": "lavatrice",
                            "field3": "classico/color",
                            "field4": "36 dosi",
                            "description": "bio presto lavatrice classico/color 36 dosi",
                            "grammage": null,
                            "price": "2.99",
                            "price_label": "€ 2,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Classico",
                                "Color"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Bucato",
                            "blueprint": {
                                "top": 7.826086956521739,
                                "left": 70.34536054021234,
                                "width": 29.654639459787667,
                                "height": 27.261387163561075
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/BIOPRESTO_36CLASSICO_1554909004_1557161400_1562259952.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/BIOPRESTO_36COLOR_1554909005_1557161401_1562259953.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_x91Vtsu_wvkHq55.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705363,
                            "field1": "orphea",
                            "field2": "salvalana sacchetti profumati per cassetti",
                            "field3": "vari tipi",
                            "field4": "",
                            "description": "orphea salvalana sacchetti profumati per cassetti vari tipi",
                            "grammage": 0,
                            "price": "2.99",
                            "price_label": "€ 2,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "No Food",
                            "subcategory": "Home",
                            "blueprint": {
                                "top": 35.52795031055901,
                                "left": 40.538004379105416,
                                "width": 38.39813060037903,
                                "height": 19.55952380952381
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8001365181726_orphea_salvalana_x3_1569920325.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_lpGwFXC_JB0pEAU.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705359,
                            "field1": "spontex",
                            "field2": "full action system",
                            "field3": "lavapavimenti",
                            "field4": "",
                            "description": "spontex full action system lavapavimenti",
                            "grammage": 0,
                            "price": "14.90",
                            "price_label": "€ 14,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Extra",
                            "blueprint": {
                                "top": 35.52795031055901,
                                "left": 78.95637454230989,
                                "width": 21.04362545769011,
                                "height": 19.55952380952381
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/Full_Action_System__Set_1432811512_1564492302.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_7JPI23u_Y3HRMNv.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705330,
                            "field1": "omino bianco",
                            "field2": "additivo totale 100 più",
                            "field3": "classico/color",
                            "field4": "500 gr",
                            "description": "omino bianco additivo totale 100 più classico/color 500 gr",
                            "grammage": 500,
                            "price": "2.29",
                            "price_label": "€ 2,29",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Classico",
                                "Color"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Bucato",
                            "blueprint": {
                                "top": 55.65217391304348,
                                "left": 0.0,
                                "width": 21.04362545769011,
                                "height": 20.67753623188406
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/omino_bianco_100piu_classico_1504705342_1542895924_1554992_fnzBt6e.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/omino_bianco_100piu_color_1504705341_1542895923_1554992594_5RSi2QW.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_Atjlqhu_vggIFxV.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705333,
                            "field1": "spuma di sciampagna",
                            "field2": "bucato delicato",
                            "field3": "neropuro argan/puro lana",
                            "field4": "1 lt",
                            "description": "spuma di sciampagna bucato delicato neropuro argan/puro lana 1 lt",
                            "grammage": 0,
                            "price": "1.59",
                            "price_label": "€ 1,59",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Neropuro argan",
                                "Puro lana"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Bucato",
                            "blueprint": {
                                "top": 55.65217391304348,
                                "left": 20.93138788202175,
                                "width": 19.453899795764407,
                                "height": 20.67753623188406
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/Spuma_di_Sciampagna_NeroPuro_Bucato_Delicato_1000_ml_1561996107.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/Spuma_di_Sciampagna_PuroLana_Bucato_Delicato_1000_ml_1561996151.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_pPVGDYw_JN2H7mr.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705331,
                            "field1": "spuma di sciampagna",
                            "field2": "additivo biancopuro oxy",
                            "field3": "active/color",
                            "field4": "1 lt",
                            "description": "spuma di sciampagna additivo biancopuro oxy active/color 1 lt",
                            "grammage": null,
                            "price": "1.49",
                            "price_label": "€ 1,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Active",
                                "Color"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Bucato",
                            "blueprint": {
                                "top": 55.40372670807454,
                                "left": 40.538004379105416,
                                "width": 19.05646838028298,
                                "height": 20.67753623188406
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8007750085051_SpumaDiSciampagnaOxyActive_1553795112.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8007750085372_SpumaDiSciampagnaOxyColor_1553795113.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_yHYJSow_naFFCwo.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705336,
                            "field1": "fairy",
                            "field2": "platinum plus lemon",
                            "field3": "lavastoviglie",
                            "field4": "x 20",
                            "description": "fairy platinum plus lemon lavastoviglie x 20",
                            "grammage": 0,
                            "price": "2.99",
                            "price_label": "€ 2,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Extra",
                            "blueprint": {
                                "top": 55.52795031055902,
                                "left": 59.747189460707645,
                                "width": 19.05646838028298,
                                "height": 20.67753623188406
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8001841002965_81683743_fairy_caps_platinum_plus_5x20_pi_-__z1Whgmp.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_PaQb8lj_dXxvqiZ.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705324,
                            "field1": "regiò",
                            "field2": "tovaglioli maxi scorta 1 velo 33x33 cm",
                            "field3": "",
                            "field4": "500 strappi",
                            "description": "regiò tovaglioli maxi scorta 1 velo 33x33 cm 500 strappi",
                            "grammage": null,
                            "price": "1.89",
                            "price_label": "€ 1,89",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Superfici",
                            "blueprint": {
                                "top": 55.52795031055902,
                                "left": 78.82389740381609,
                                "width": 21.176102596183917,
                                "height": 20.67753623188406
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8056684220007_TOVAGLIOLI_REGIO_1VELO_33X33_MAXI_SCORTA_S50_PRS1FhZ.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_zC1zvmO_GIXvuDm.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705340,
                            "field1": "smac ",
                            "field2": "sgrassatore/scioglicalcare/bagno",
                            "field3": "vari tipi",
                            "field4": "650 ml",
                            "description": "smac  sgrassatore/scioglicalcare/bagno vari tipi 650 ml",
                            "grammage": 650,
                            "price": "1.29",
                            "price_label": "€ 1,29",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Sgrassatore",
                                "Scioglicalcare",
                                "Bagno"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Superfici",
                            "blueprint": {
                                "top": 76.39751552795032,
                                "left": 0.0,
                                "width": 21.176102596183917,
                                "height": 20.869565217391305
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8003650002518_1558373360.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8003650006325_1558373372.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_AKgBiZu_MMHC9U6.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705338,
                            "field1": "smac",
                            "field2": "brilla acciaio crema",
                            "field3": "",
                            "field4": "500 ml",
                            "description": "smac brilla acciaio crema 500 ml",
                            "grammage": 500,
                            "price": "1.89",
                            "price_label": "€ 1,89",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Spray",
                                "Crema"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Superfici",
                            "blueprint": {
                                "top": 76.27329192546584,
                                "left": 20.79891074352794,
                                "width": 19.586376934258215,
                                "height": 20.869565217391305
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/Smac_Brilla_Acciaio_Crema_500ml_std_1489663722_1496822998__1dfThso.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_SLAySzY_Mbvc7Az.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705335,
                            "field1": "sole",
                            "field2": "piatti",
                            "field3": "aceto/limone verde/normale",
                            "field4": "1,10 lt",
                            "description": "sole piatti aceto/limone verde/normale 1,10 lt",
                            "grammage": 1100,
                            "price": "0.79",
                            "price_label": "€ 0,79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Aceto",
                                "Limone verde",
                                "Normale"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Superfici",
                            "blueprint": {
                                "top": 76.39751552795032,
                                "left": 40.27305010211779,
                                "width": 19.586376934258215,
                                "height": 19.25465838509317
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8002910022235_sole_piatti_limone_verde_1559724237.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8002910022273_sole_piatti_aceto_1559724238.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_HipNK2M_q4UybPp.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705339,
                            "field1": "bref",
                            "field2": "pavimenti",
                            "field3": "vari tipi",
                            "field4": "1,25 lt",
                            "description": "bref pavimenti vari tipi 1,25 lt",
                            "grammage": 1250,
                            "price": "1.79",
                            "price_label": "€ 1,79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Superfici",
                            "blueprint": {
                                "top": 76.5217391304348,
                                "left": 59.48223518372004,
                                "width": 19.586376934258215,
                                "height": 19.25465838509317
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/BrefBrillante1250_IT_P122767_1522944763_1563272717.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/BrefBrillanteFloralEu1250ml_IT_P122769_1522944763_1563272717.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_rcbmKqw_zleDIxv.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705371,
                            "field1": "diesse",
                            "field2": "piatti",
                            "field3": "fondi/piani/quadri",
                            "field4": "1 kg",
                            "description": "diesse piatti fondi/piani/quadri 1 kg",
                            "grammage": null,
                            "price": "2.49",
                            "price_label": "€ 2,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Fondi",
                                "Piani",
                                "Quadri"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Extra",
                            "blueprint": {
                                "top": 76.5217391304348,
                                "left": 78.95637454230989,
                                "width": 21.04362545769011,
                                "height": 19.25465838509317
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8032642751028_1558422623.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/PIATTI_DIESSE_PIANI_KG.1_1565341824.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_MOogfSp_cGNkCbX.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 6,
                    "image_file": "/media/interactive_flyer_19/pages/page_6.jpg",
                    "products": [
                        {
                            "item_id": 705465,
                            "field1": "kaloderma",
                            "field2": "crema viso bio ",
                            "field3": "",
                            "field4": "",
                            "description": "kaloderma crema viso bio ",
                            "grammage": null,
                            "price": "4.99",
                            "price_label": "€ 4,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Viso",
                            "blueprint": {
                                "top": 7.950310559006211,
                                "left": 0.0,
                                "width": 28.992253767318623,
                                "height": 27.080745341614907
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8004395130191_1558421952.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8004395130238_1558421996.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_YBrJuKp_c9rzPts.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705461,
                            "field1": "olaz",
                            "field2": "crema viso",
                            "field3": "vari tipi",
                            "field4": "",
                            "description": "olaz crema viso vari tipi",
                            "grammage": 50,
                            "price": "5.89",
                            "price_label": "€ 5,89",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Viso",
                            "blueprint": {
                                "top": 7.950310559006211,
                                "left": 28.880016191650263,
                                "width": 30.581979429244324,
                                "height": 27.080745341614907
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/5000174243598_1553794935.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/5410076363902_1553794964.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_s61r6K0_tkCcdQW.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705313,
                            "field1": "laura rossi",
                            "field2": "profumo equivalente",
                            "field3": "donna/uomo",
                            "field4": "100 ml",
                            "description": "laura rossi profumo equivalente donna/uomo 100 ml",
                            "grammage": 100,
                            "price": "3.99",
                            "price_label": "€ 3,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Donna",
                                "Uomo"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Corpo",
                            "blueprint": {
                                "top": 35.27950310559007,
                                "left": 0.0,
                                "width": 20.9111483191963,
                                "height": 19.87577639751553
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/Laura_Rossi_Aileen_For_Her_1558712096.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/Laura_Rossi_Aura_di_Io_For_Him_1558712097.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/Laura_Rossi_One_Clio_For_Him_1558712098.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_llbG5nX_BJ82fB6.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705317,
                            "field1": "labello",
                            "field2": "burro cacao",
                            "field3": "vari tipi",
                            "field4": "",
                            "description": "labello burro cacao vari tipi",
                            "grammage": 0,
                            "price": "1.49",
                            "price_label": "€ 1,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Viso",
                            "blueprint": {
                                "top": 35.27950310559007,
                                "left": 21.05374523910283,
                                "width": 18.679276527627007,
                                "height": 20.248447204968947
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/LABELLO_BLU_1540375847_1569919419.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/LABELLO_ROSA_1540375848_1569919420.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_TnK8ULp_WqEKpu6.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705382,
                            "field1": "dermomed",
                            "field2": "sapone liquido",
                            "field3": "varie profumazioni",
                            "field4": "1 lt",
                            "description": "dermomed sapone liquido varie profumazioni 1 lt",
                            "grammage": null,
                            "price": "1.19",
                            "price_label": "€ 1,19",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Corpo",
                            "blueprint": {
                                "top": 35.27950310559007,
                                "left": 40.13045318221126,
                                "width": 19.341662220096048,
                                "height": 19.627329192546583
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8032680390807_dermomed_crema_sapone_olio_di_argan_1559724539.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8032680397073_dermomed_crema_sapone_talco_e_iris_1559724554.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_Tg1YQZJ_toO39uN.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705466,
                            "field1": "hq",
                            "field2": "trattamento viso",
                            "field3": "crema/maschera vari trattamenti",
                            "field4": "10/15 ml",
                            "description": "hq trattamento viso crema/maschera vari trattamenti 10/15 ml",
                            "grammage": 0,
                            "price": "1.50",
                            "price_label": "€ 1,50",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Crema",
                                "Maschera"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Viso",
                            "blueprint": {
                                "top": 0.0,
                                "left": 59.73706967929493,
                                "width": 19.341662220096048,
                                "height": 22.608695652173914
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8050043996466_1555676619.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8050043996558_1555676668.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_WhWCBEN_aRhSj4C.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705315,
                            "field1": "fria",
                            "field2": "salviettine struccanti utility con tappo",
                            "field3": "",
                            "field4": "pz 64",
                            "description": "fria salviettine struccanti utility con tappo pz 64",
                            "grammage": null,
                            "price": "1.89",
                            "price_label": "€ 1,89",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Viso",
                            "blueprint": {
                                "top": 0.0,
                                "left": 78.81377762240336,
                                "width": 21.186222377596643,
                                "height": 22.608695652173914
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8009432016957_1554886385.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_SojftRW_tCJMMAr.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705480,
                            "field1": "foxy",
                            "field2": "carta igienica mega",
                            "field3": "2 veli - 4 rotoloni",
                            "field4": "",
                            "description": "foxy carta igienica mega 2 veli - 4 rotoloni",
                            "grammage": 0,
                            "price": "1.89",
                            "price_label": "€ 1,89",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Extra",
                            "blueprint": {
                                "top": 22.608695652173914,
                                "left": 59.73706967929493,
                                "width": 40.26293032070507,
                                "height": 32.67080745341615
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/Foxy_Mega_4_igienica_1491647551_1492502992_1537264428_1560879356.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_1l4aZS2_Q9OHGvx.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705388,
                            "field1": "neutromed",
                            "field2": "bagnoschiuma",
                            "field3": "varie profumazioni",
                            "field4": "500/650 ml",
                            "description": "neutromed bagnoschiuma varie profumazioni 500/650 ml",
                            "grammage": 0,
                            "price": "1.49",
                            "price_label": "€ 1,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Corpo",
                            "blueprint": {
                                "top": 55.52795031055901,
                                "left": 0.0,
                                "width": 21.31869951609045,
                                "height": 20.49689440993789
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8015700154436_neutro_med_idratante_bagno_1559724337_1559816861.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8015700155822_neutro_med_sensual_e_oil_bagno_1559724356_1559816862.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_bA86bEE_7fYKhfb.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705395,
                            "field1": "neutromed",
                            "field2": "doccia shampoo",
                            "field3": "power/sport/idratante",
                            "field4": "250 ml",
                            "description": "neutromed doccia shampoo power/sport/idratante 250 ml",
                            "grammage": 250,
                            "price": "0.99",
                            "price_label": "€ 0,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Power",
                                "Sport",
                                "Idratante"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Capelli",
                            "blueprint": {
                                "top": 55.65217391304348,
                                "left": 21.063865020515557,
                                "width": 19.331542438683325,
                                "height": 20.49689440993789
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/neutro_med_1563199622_1569917866_1569917919.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/neutro_med_idratante_1563204535_1569917866_1569917919.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_xnrotMC_8W2snUy.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705394,
                            "field1": "felce azzurra",
                            "field2": "doccia gel",
                            "field3": "vari tipi",
                            "field4": "250 ml",
                            "description": "felce azzurra doccia gel vari tipi 250 ml",
                            "grammage": 250,
                            "price": "0.99",
                            "price_label": "€ 0,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Corpo",
                            "blueprint": {
                                "top": 55.65217391304348,
                                "left": 40.27305010211779,
                                "width": 19.331542438683325,
                                "height": 20.49689440993789
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8001280012464_felce_azzurra_doccia_gel_1559668151.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/felce_doccia_lime_1492100088_1541528578_1569922352.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_UrbgzBG_6xeC4J9.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705383,
                            "field1": "spuma di sciampagna",
                            "field2": "sapone liquido",
                            "field3": "vari tipi",
                            "field4": "400 ml",
                            "description": "spuma di sciampagna sapone liquido vari tipi 400 ml",
                            "grammage": 400,
                            "price": "1.49",
                            "price_label": "€ 1,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Corpo",
                            "blueprint": {
                                "top": 55.65217391304348,
                                "left": 59.48223518372004,
                                "width": 19.331542438683325,
                                "height": 20.49689440993789
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8007750004403_SdS_SaponeLiquido_Marsiglia_400_1499680639_1_aZn4C48.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8007750006704_SdS_Saponecrema_Argan_400ml_1499680640_1569918149.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_zSbHUbR_nTIH7O0.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705484,
                            "field1": "felce azzurra",
                            "field2": "bagnodoccia bio",
                            "field3": "aloe vera e limone/argan e miele",
                            "field4": "500 ml",
                            "description": "felce azzurra bagnodoccia bio aloe vera e limone/argan e miele 500 ml",
                            "grammage": 500,
                            "price": "2.99",
                            "price_label": "€ 2,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Aloe vera e limone",
                                "Argan e miele"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Corpo",
                            "blueprint": {
                                "top": 55.40372670807454,
                                "left": 78.95637454230989,
                                "width": 21.04362545769011,
                                "height": 20.99378881987578
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/felce_azzurra_argan_500ml_1564495414.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/felce_azzurra_aloe_500ml_1564495414.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_FlcWnTm_rhSakof.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705456,
                            "field1": "geomar",
                            "field2": "crema corpo",
                            "field3": "rassodante 500 ml/scrub 600 gr/anticellulite 650 ml - vari tipi",
                            "field4": "",
                            "description": "geomar crema corpo rassodante 500 ml/scrub 600 gr/anticellulite 650 ml - vari tipi",
                            "grammage": 0,
                            "price": "7.99",
                            "price_label": "€ 7,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Rassodante",
                                "Scrub",
                                "Anticellulite"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Corpo",
                            "blueprint": {
                                "top": 76.39751552795032,
                                "left": 0.0,
                                "width": 21.04362545769011,
                                "height": 19.25465838509317
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8003510022915_1554887131.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8003510014408_1554887122_1554894206.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8003510014316_Geomar_fango_alga_oceanica_1558372976.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8003510022915_Geomar_fango_argilla_bianca_1558373098.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8003510030200_Geomar_scrub_purificante_1558373221.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8003510031047_Geomar_crema_fango_rassodante_per_cellulite__90iUvNY.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_4Y39Mb4_b0psVzn.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705451,
                            "field1": "l'erboristica ",
                            "field2": "crema mani mandorle dolci",
                            "field3": "",
                            "field4": "75 ml",
                            "description": "l'erboristica  crema mani mandorle dolci 75 ml",
                            "grammage": 75,
                            "price": "2.99",
                            "price_label": "€ 2,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Mani",
                                "Corpo"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Corpo",
                            "blueprint": {
                                "top": 76.5217391304348,
                                "left": 20.93138788202175,
                                "width": 19.453899795764407,
                                "height": 19.25465838509317
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8002842169978_1557246209.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_4GK1uQH_Df8qPPW.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705386,
                            "field1": "intima roberts ",
                            "field2": "salviettine intime camomilla",
                            "field3": "x 12",
                            "field4": "",
                            "description": "intima roberts  salviettine intime camomilla x 12",
                            "grammage": 0,
                            "price": "1.29",
                            "price_label": "€ 1,29",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Salviettine",
                                "Detergente"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Corpo",
                            "blueprint": {
                                "top": 76.64596273291926,
                                "left": 59.349758045226224,
                                "width": 19.453899795764407,
                                "height": 23.35403726708075
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/Roberts_salviettine_intime_Camomilla_1492766412_1569918062.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_4jwsMMz_SRn2Xr0.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705385,
                            "field1": "sauber",
                            "field2": "igiene intima extra delicato",
                            "field3": "",
                            "field4": "200 ml",
                            "description": "sauber igiene intima extra delicato 200 ml",
                            "grammage": 200,
                            "price": "1.89",
                            "price_label": "€ 1,89",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Intima",
                            "blueprint": {
                                "top": 76.64596273291926,
                                "left": 78.95637454230989,
                                "width": 21.04362545769011,
                                "height": 23.35403726708075
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8005520003007_sauber_detergenete_intimo_extra_delicato_1557246954.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_mmTD7mX_wdCY0g5.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 7,
                    "image_file": "/media/interactive_flyer_19/pages/page_7.jpg",
                    "products": [
                        {
                            "item_id": 707441,
                            "field1": "splendidi e splendenti",
                            "field2": "salviette bimbi con tappo",
                            "field3": "",
                            "field4": "pz 64",
                            "description": "splendidi e splendenti salviette bimbi con tappo pz 64",
                            "grammage": 0,
                            "price": "1.40",
                            "price_label": "€ 1,40",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Corpo",
                            "blueprint": {
                                "top": 22.608695652173914,
                                "left": 0.0,
                                "width": 24.090599643047707,
                                "height": 24.223602484472053
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8009432019170_1554886409_1555055271.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_RITVYCS_oqNRi6q.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705487,
                            "field1": "mellin",
                            "field2": "pastina",
                            "field3": "semini stelline tempestina",
                            "field4": "320 gr",
                            "description": "mellin pastina semini/stelline/tempestina 320 gr",
                            "grammage": 320,
                            "price": "1.00",
                            "price_label": "€ 1,00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Semini",
                                "Stelline",
                                "Tempestina"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Pasta",
                            "blueprint": {
                                "top": 20.99378881987578,
                                "left": 23.315976374910303,
                                "width": 19.98380834973964,
                                "height": 25.714285714285715
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/Mellin_Le_Pastine_Semini_1549618997_1569915645.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/mellin_pastina_stelline_1541754794_1549360934_1569915645.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_znWEW03_5nX6uJL.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705318,
                            "field1": "johnson's baby",
                            "field2": "shampoo",
                            "field3": "",
                            "field4": "750 ml",
                            "description": "johnson's baby shampoo 750 ml",
                            "grammage": 750,
                            "price": "2.25",
                            "price_label": "€ 2,25",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Capelli",
                            "blueprint": {
                                "top": 20.000000000000004,
                                "left": 42.92259287199397,
                                "width": 16.40692561040681,
                                "height": 26.459627329192546
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/3574669907729_Johnsons_baby_shampoo_750_ml_1561995832.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_vdmmbwo_s0chZf3.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705320,
                            "field1": "johnson's",
                            "field2": "salviettine bimbi",
                            "field3": "cotton touch",
                            "field4": "56 pz x 2",
                            "description": "johnson's salviettine bimbi cotton touch 56 pz x 2",
                            "grammage": 0,
                            "price": "1.99",
                            "price_label": "€ 1,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Corpo",
                            "blueprint": {
                                "top": 19.87577639751553,
                                "left": 58.9523266297448,
                                "width": 20.513716903714876,
                                "height": 26.459627329192546
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/3574661421896_johnsons_cottontouch_salviette_x112_1569920830.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_4dCZomu_YnvxqWg.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705321,
                            "field1": "pampers",
                            "field2": "pannolini baby dry",
                            "field3": "varie misure",
                            "field4": "",
                            "description": "pampers pannolini baby dry varie misure",
                            "grammage": 0,
                            "price": "4.49",
                            "price_label": "€ 4,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Corpo",
                            "blueprint": {
                                "top": 18.01242236024845,
                                "left": 78.82389740381609,
                                "width": 21.176102596183917,
                                "height": 28.322981366459626
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/Pampers_baby_dry_4_1531745470_1540823893_1553275750_155387_OQkblsS.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/Pampers_baby_dry_5_1531745471_1540823894_1553275751_155387_jYbb1Ak.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_5FXOIbO_jdIJtPj.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705488,
                            "field1": "innova",
                            "field2": "giochi",
                            "field3": "",
                            "field4": "",
                            "description": "innova giochi",
                            "grammage": 0,
                            "price": "4.49",
                            "price_label": "€ 4,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": ["Cucina"],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "No Food",
                            "subcategory": "Home",
                            "blueprint": {
                                "top": 46.7080745341615,
                                "left": 38.8158015786859,
                                "width": 24.090599643047707,
                                "height": 27.080745341614907
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/ingioco_giochiamo_in_cucina_1569936125_FAvkLzy.jpg",
                                    "cropped": false
                                },

                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_L2ef8OG_Dney4cC.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705488,
                            "field1": "innova",
                            "field2": "",
                            "field3": "",
                            "field4": "",
                            "description": "innova giochi",
                            "grammage": 0,
                            "price": "4.49",
                            "price_label": "€ 4,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": ["Costruzioni"],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "No Food",
                            "subcategory": "Home",
                            "blueprint": {
                                "top": 47.08074534161491,
                                "left": 0.0,
                                "width": 38.795562015860455,
                                "height": 27.080745341614907
                            },
                            "images": [

                                {
                                    "image_file": "/media/interactive_flyer_19/products/innova_block_animal_1569936127_gCLmSjC.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_VoHcasQ_Y0hX9ub.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705488,
                            "field1": "innova",
                            "field2": "giochi",
                            "field3": "",
                            "field4": "",
                            "description": "innova giochi",
                            "grammage": 0,
                            "price": "4.49",
                            "price_label": "€ 4,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": ["Lavagna Magica"],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "No Food",
                            "subcategory": "Home",
                            "blueprint": {
                                "top": 48.32298136645963,
                                "left": 61.20443798413955,
                                "width": 38.795562015860455,
                                "height": 27.080745341614907
                            },
                            "images": [

                                {
                                    "image_file": "/media/interactive_flyer_19/products/ingioco_lavagna_magica_1569936126_x8QB0VZ.jpg",
                                    "cropped": false
                                },

                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_WcQiuTV_ZDP9hwS.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705489,
                            "field1": "innova",
                            "field2": "peluches con plaid",
                            "field3": "4 modelli",
                            "field4": "",
                            "description": "innova peluches con plaid 4 modelli",
                            "grammage": 0,
                            "price": "8.90",
                            "price_label": "€ 8,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "No Food",
                            "subcategory": "Home",
                            "blueprint": {
                                "top": 72.9192546583851,
                                "left": 0.0,
                                "width": 38.795562015860455,
                                "height": 27.080745341614907
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/i_morbidoni_coniglio_1569936041.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_aUpzxkt_xcjZAVn.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705488,
                            "field1": "innova",
                            "field2": "giochi",
                            "field3": "",
                            "field4": "",
                            "description": "innova giochi",
                            "grammage": 0,
                            "price": "4.49",
                            "price_label": "€ 4,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": ["Trattore"],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "No Food",
                            "subcategory": "Home",
                            "blueprint": {
                                "top": 72.67080745341615,
                                "left": 39.87561868663637,
                                "width": 25.680325304973408,
                                "height": 22.857142857142858
                            },
                            "images": [

                                {
                                    "image_file": "/media/interactive_flyer_19/products/ingioco_giochiamo_in_fattoria_1569936126_wiNL6qN.jpg",
                                    "cropped": false
                                },

                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_dcoQJ0Y_ezr9P9o.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705488,
                            "field1": "innova",
                            "field2": "giochi",
                            "field3": "",
                            "field4": "",
                            "description": "innova giochi",
                            "grammage": 0,
                            "price": "4.49",
                            "price_label": "€ 4,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": ["Allegro Microfono"],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "No Food",
                            "subcategory": "Home",
                            "blueprint": {
                                "top": 75.77639751552796,
                                "left": 69.28554343226187,
                                "width": 30.71445656773813,
                                "height": 19.751552795031056
                            },
                            "images": [

                                {
                                    "image_file": "/media/interactive_flyer_19/products/innova_allegro_microfono_1569936126_NdVEtiI.jpg",
                                    "cropped": false
                                },

                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_wn5ZbSk_3eIiGYg.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 8,
                    "image_file": "/media/interactive_flyer_19/pages/page_8.jpg",
                    "products": [
                        {
                            "item_id": 705420,
                            "field1": "neutromed",
                            "field2": "deodorante dermo spray",
                            "field3": "varie profumazioni",
                            "field4": "150 ml",
                            "description": "neutromed deodorante dermo spray varie profumazioni 150 ml",
                            "grammage": 150,
                            "price": "1.49",
                            "price_label": "€ 1,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Corpo",
                            "blueprint": {
                                "top": 8.198757763975156,
                                "left": 0.0,
                                "width": 28.32986807484957,
                                "height": 26.832298136645967
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8015700156768_1557244341.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8015700156836_1557244341_1557310959.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_MqcgZm2_PaPqJQ2.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705428,
                            "field1": "carefree ",
                            "field2": "assorbenti proteggi slip",
                            "field3": "vari tipi",
                            "field4": "",
                            "description": "carefree  assorbenti proteggi slip vari tipi",
                            "grammage": null,
                            "price": "1.49",
                            "price_label": "€ 1,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Intima",
                            "blueprint": {
                                "top": 7.826086956521739,
                                "left": 28.350107637675023,
                                "width": 30.84693370623193,
                                "height": 27.080745341614907
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/3574660067682_CarefreeProteggiSlip44_1553790947.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/3574660550184_CarefreeProteggi24_1553791024.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_Y7iyX8Z_XC7a4JR.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705379,
                            "field1": "mantovani  ",
                            "field2": "saponette",
                            "field3": "argan/neutra",
                            "field4": "100 gr x 4",
                            "description": "mantovani   saponette argan/neutra 100 gr x 4",
                            "grammage": 400,
                            "price": "1.79",
                            "price_label": "€ 1,79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Argan",
                                "Neutra"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Corpo",
                            "blueprint": {
                                "top": 0.0,
                                "left": 59.747189460707645,
                                "width": 19.18894551877678,
                                "height": 22.484472049689444
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8002340008281_MantovaniClassico_1553793426.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8002340012424_MantovaniArganX4_1553793426.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_X8xqxNx_fv99lsr.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705419,
                            "field1": "malizia",
                            "field2": "deodorante donna",
                            "field3": "varie profumazioni",
                            "field4": "100 ml",
                            "description": "malizia deodorante donna varie profumazioni 100 ml",
                            "grammage": 100,
                            "price": "1.39",
                            "price_label": "€ 1,39",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Corpo",
                            "blueprint": {
                                "top": 0.0,
                                "left": 78.95637454230989,
                                "width": 21.043625457690112,
                                "height": 22.484472049689444
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8003510015474_MaliziaDeodoranteDonnaPurple_1553793560.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8003510020478_MaliziaDeodoranteDonnaSeduction_1553793650.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_HtxnMYm_iljaNf9.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705426,
                            "field1": "nuvenia",
                            "field2": "assorbenti/",
                            "field3": "proteggi slip",
                            "field4": "vari tipi",
                            "description": "nuvenia assorbenti/ proteggi slip vari tipi",
                            "grammage": 0,
                            "price": "1.49",
                            "price_label": "€ 1,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Intima",
                            "blueprint": {
                                "top": 22.608695652173914,
                                "left": 59.747189460707645,
                                "width": 40.25281053929235,
                                "height": 32.67080745341615
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/nuvenia_notte_sottile_x10_1537463158.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/Nuvenia_Ultra_SOTTILE_Notte_10x12_1532159692_1535624218_1554364160.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/7322540678628_1554892782.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_UsQqjxT_DZwjHg7.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705415,
                            "field1": "thermo therapy",
                            "field2": "",
                            "field3": "",
                            "field4": "",
                            "description": "thermo therapy",
                            "grammage": 0,
                            "price": "3.99",
                            "price_label": "€ 3,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Fascia",
                                "Cerotti"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Medicinali",
                            "subcategory": "Uso Esterno",
                            "blueprint": {
                                "top": 35.40372670807454,
                                "left": 0.0,
                                "width": 59.32951848240077,
                                "height": 19.751552795031056
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8009432012218_thermo_therapy_2_dispositivi_cervicale__1569920373.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_j49G0l3_Jtd11Cr.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705422,
                            "field1": "i provenzali",
                            "field2": "deodorante argan bio",
                            "field3": "roll-on 50 ml/vapo 75 ml",
                            "field4": "",
                            "description": "i provenzali deodorante argan bio roll-on 50 ml/vapo 75 ml",
                            "grammage": null,
                            "price": "2.59",
                            "price_label": "€ 2,59",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Roll",
                                "Vapo"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Corpo",
                            "blueprint": {
                                "top": 55.52795031055901,
                                "left": 0.0,
                                "width": 20.911148319196293,
                                "height": 20.745341614906835
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8025796007592_1558422573.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/I_Provenzali_Bio_Deo_Vapo_Biologico_Argan_75_ml_1569922702.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_ghwVawh_RnX11yU.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705424,
                            "field1": "o.b.",
                            "field2": "assorbenti",
                            "field3": "vari tipi",
                            "field4": "",
                            "description": "o.b. assorbenti vari tipi",
                            "grammage": 0,
                            "price": "2.29",
                            "price_label": "€ 2,29",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Intima",
                            "blueprint": {
                                "top": 76.39751552795032,
                                "left": 0.0,
                                "width": 20.911148319196293,
                                "height": 19.25465838509317
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/3574660240009_OBProConfortTampone16_1553790992.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/4001683011426_OBProConfort16_1553791138.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_vB1KDbT_j30ffaW.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705373,
                            "field1": "scholl",
                            "field2": "linea",
                            "field3": "vari tipi",
                            "field4": "",
                            "description": "scholl linea vari tipi",
                            "grammage": null,
                            "price": "11.50",
                            "price_label": "€ 3,59",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Corpo",
                            "blueprint": {
                                "top": 55.52795031055901,
                                "left": 20.93138788202175,
                                "width": 38.39813060037903,
                                "height": 40.00000000000001
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8410104602147_1555676714.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8410104602154_1555676725.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_nuqFKWW_gvJ1ohW.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705411,
                            "field1": "veet",
                            "field2": "crema depilatoria",
                            "field3": "vari tipi",
                            "field4": "",
                            "description": "veet crema depilatoria vari tipi",
                            "grammage": null,
                            "price": "3.99",
                            "price_label": "€ 3,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Intima",
                            "blueprint": {
                                "top": 70.18633540372672,
                                "left": 59.73706967929493,
                                "width": 19.341662220096048,
                                "height": 29.813664596273295
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8002910043575_1555675619.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8002910043636_1555675619.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_VvUK7kV_q6BnvfW.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705408,
                            "field1": "veet",
                            "field2": "rasoio sensitive precision elettrico viso corpo",
                            "field3": "",
                            "field4": "",
                            "description": "veet rasoio sensitive precision elettrico viso corpo",
                            "grammage": null,
                            "price": "21.90",
                            "price_label": "€ 21,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Corpo",
                            "blueprint": {
                                "top": 70.18633540372672,
                                "left": 79.73099781044729,
                                "width": 20.269002189552708,
                                "height": 29.813664596273295
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8002910045517_1555675633.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_oHva2AQ_CahCBvD.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 9,
                    "image_file": "/media/interactive_flyer_19/pages/page_9.jpg",
                    "products": [
                        {
                            "item_id": 705405,
                            "field1": "felce azzurra",
                            "field2": "talco",
                            "field3": "",
                            "field4": "100 gr",
                            "description": "felce azzurra talco 100 gr",
                            "grammage": 100,
                            "price": "0.59",
                            "price_label": "€ 0,59",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Corpo",
                            "blueprint": {
                                "top": 0.0,
                                "left": 0.0,
                                "width": 20.93138788202175,
                                "height": 22.36024844720497
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8001280026034_felce_azzurra_saponetta_talco_fresco_1559668385.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_aClP3fd_YdOJjcr.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705429,
                            "field1": "roberts",
                            "field2": "cotonfioc",
                            "field3": "con camomilla",
                            "field4": "260 pz",
                            "description": "roberts cotonfioc con camomilla 260 pz",
                            "grammage": null,
                            "price": "1.49",
                            "price_label": "€ 1,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Corpo",
                            "blueprint": {
                                "top": 0.0,
                                "left": 20.93138788202175,
                                "width": 19.474139358589856,
                                "height": 22.36024844720497
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8002410008326_1558423843.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_u79RCZp_q3VRnkm.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705398,
                            "field1": "keramine h",
                            "field2": "linea",
                            "field3": "capelli",
                            "field4": "",
                            "description": "keramine h linea capelli",
                            "grammage": 300,
                            "price": "3.99",
                            "price_label": "€ 3,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Capelli",
                            "blueprint": {
                                "top": 0.0,
                                "left": 40.40552724061161,
                                "width": 35.50387311634069,
                                "height": 34.90683229813665
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8019971030154_Keramine_H_Kh_Multi_Vita_Color_Shampoo_1569922089.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8019971037214_Keramine_H_Lh_Anticaduta_Shampoo_1569922102.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_8SGcy68_z6Jkv3W.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705471,
                            "field1": "splendor",
                            "field2": "lacca",
                            "field3": "forte/normale",
                            "field4": "300 ml",
                            "description": "splendor lacca forte/normale 300 ml",
                            "grammage": 300,
                            "price": "1.19",
                            "price_label": "€ 1,19",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Forte",
                                "Normale"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Capelli",
                            "blueprint": {
                                "top": 7.950310559006211,
                                "left": 75.49172937864542,
                                "width": 24.50827062135458,
                                "height": 27.204968944099377
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8003510000579_1557246535.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/splendid_lacca_normale_300_ml_1557506498.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_8djcPW0_3GSqEjZ.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705474,
                            "field1": "garnier belle color",
                            "field2": "shampoo colorante",
                            "field3": "varie nuances",
                            "field4": "",
                            "description": "garnier belle color shampoo colorante varie nuances",
                            "grammage": null,
                            "price": "4.89",
                            "price_label": "€ 4,89",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Capelli",
                            "blueprint": {
                                "top": 22.484472049689444,
                                "left": 0.0,
                                "width": 40.27305010211779,
                                "height": 32.79503105590062
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8001960120120_1554885158.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/SHAMPOO_COL.BELLE_COLOR_CASTANO_SCURO_N.24_1569923302.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_IyMxAdn_hgmFCv4.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705472,
                            "field1": "testanera",
                            "field2": "palette styling",
                            "field3": "",
                            "field4": "vari tipi",
                            "description": "testanera palette styling vari tipi",
                            "grammage": 0,
                            "price": "2.99",
                            "price_label": "€ 2,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Capelli",
                            "blueprint": {
                                "top": 35.1552795031056,
                                "left": 40.40552724061161,
                                "width": 19.20918508160224,
                                "height": 20.372670807453417
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/SKP_Palette-Styling_970x1400_INVISIBILE-Pasta_1557932404_1_gDfADar.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/principale_8015700154320_1560878996.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_YzMvTZZ_RiGFP8I.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705476,
                            "field1": "schwarzkopf",
                            "field2": "shampoo colorante",
                            "field3": "varie nuances",
                            "field4": "",
                            "description": "schwarzkopf shampoo colorante varie nuances",
                            "grammage": null,
                            "price": "4.99",
                            "price_label": "€ 4,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Capelli",
                            "blueprint": {
                                "top": 35.40372670807454,
                                "left": 59.747189460707645,
                                "width": 19.20918508160224,
                                "height": 20.000000000000004
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/tone_supreme_int_baseline_6_0_light_brown_970x1400_1562056496.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/tone_supreme_int_baseline_7_1_ashy_dark_blonde_970x1400_1562056497.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_cLCLecU_XUkGce3.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705406,
                            "field1": "naturaverde ",
                            "field2": "strisce depilatorie corpo cotone ",
                            "field3": "",
                            "field4": "20 + 10 pz",
                            "description": "naturaverde  strisce depilatorie corpo cotone  20 + 10 pz",
                            "grammage": null,
                            "price": "2.99",
                            "price_label": "€ 2,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Corpo",
                            "blueprint": {
                                "top": 35.1552795031056,
                                "left": 79.22132881929751,
                                "width": 20.778671180702492,
                                "height": 20.000000000000004
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/Naturaverde_striscie_depilatorie_braccia_e_gambe_155871314_N03qeei.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_kLAtlIn_on6cxIH.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705457,
                            "field1": "nivea men ",
                            "field2": "crema viso idratante",
                            "field3": "",
                            "field4": "75 ml",
                            "description": "nivea men  crema viso idratante 75 ml",
                            "grammage": 75,
                            "price": "4.90",
                            "price_label": "€ 4,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Viso",
                            "blueprint": {
                                "top": 55.52795031055901,
                                "left": 0.0,
                                "width": 20.778671180702492,
                                "height": 20.62111801242236
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/4005808888085_NiveaMenCremaVisoIdrantante75_1553791764.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_THlihkh_dPTdLUz.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705399,
                            "field1": "ultra dolce ",
                            "field2": "balsamo 200 ml/shampoo 250 ml",
                            "field3": "vari tipi",
                            "field4": "",
                            "description": "ultra dolce  balsamo 200 ml/shampoo 250 ml vari tipi",
                            "grammage": null,
                            "price": "1.49",
                            "price_label": "€ 1,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Balsamo",
                                "Shampoo"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Capelli",
                            "blueprint": {
                                "top": 55.52795031055901,
                                "left": 20.93138788202175,
                                "width": 38.92803915435426,
                                "height": 20.62111801242236
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/3600541887626_FructisUltraDolceShampooCapelliDelicati_1553791106.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/3600541887985_FructisBalsamoUltraDolceBalsamoDelicati200_1_D8kIZXt.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_zP9ILO2_Ff1OaFM.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705423,
                            "field1": "malizia",
                            "field2": "deodorante spray uomo",
                            "field3": "vari tipi",
                            "field4": "150 ml",
                            "description": "malizia deodorante spray uomo vari tipi 150 ml",
                            "grammage": 150,
                            "price": "1.99",
                            "price_label": "€ 1,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Corpo",
                            "blueprint": {
                                "top": 55.77639751552796,
                                "left": 59.747189460707645,
                                "width": 19.18894551877679,
                                "height": 20.62111801242236
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8003510001729_1555675668.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8003510020492_1555675697.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_ynufXHg_nEAkQyX.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705445,
                            "field1": "neutromed",
                            "field2": "schiuma da barba",
                            "field3": "fresca/ultrarapida",
                            "field4": "300 ml",
                            "description": "neutromed schiuma da barba fresca/ultrarapida 300 ml",
                            "grammage": 300,
                            "price": "1.29",
                            "price_label": "€ 1,29",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Fresca",
                                "Ultrarapida"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Corpo",
                            "blueprint": {
                                "top": 55.52795031055901,
                                "left": 78.82389740381609,
                                "width": 21.176102596183917,
                                "height": 20.62111801242236
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/NEUTROMED_SCHIUMA_DA_BARBA_ULTRARAPIDA_1531810670_1560874655.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/58728_1550164516_1560874655.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_fQJaPXd_vmxg9Bz.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705447,
                            "field1": "bic",
                            "field2": "rasoi flex4 ",
                            "field3": "",
                            "field4": "4 pz",
                            "description": "bic rasoi flex4  4 pz",
                            "grammage": null,
                            "price": "2.49",
                            "price_label": "€ 2,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Viso",
                            "blueprint": {
                                "top": 76.39751552795032,
                                "left": 0.0,
                                "width": 21.176102596183917,
                                "height": 23.602484472049692
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/3086123242555_1554884321.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_EBQW7xz_ahiHHcQ.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705449,
                            "field1": "wilkinson",
                            "field2": "rasoi sword extra3 essentials ",
                            "field3": "x 4+2/lady x 4",
                            "field4": "",
                            "description": "wilkinson rasoi sword extra3 essentials  x 4+2/lady x 4",
                            "grammage": 0,
                            "price": "2.49",
                            "price_label": "€ 2,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Extra3 4+2",
                                "Extra3 Lady x 4"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Viso",
                            "blueprint": {
                                "top": 76.39751552795032,
                                "left": 20.93138788202175,
                                "width": 19.18894551877679,
                                "height": 23.602484472049692
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/wilkinson_Extra_3_Essentials_42_rasoi_1436341050_1569916593.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/principale_4027800005901_1569923033.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_U3dnVvD_leSwP1d.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705448,
                            "field1": "gillette",
                            "field2": "rasoi blue ii",
                            "field3": "vari tipi",
                            "field4": "4/5 pz",
                            "description": "gillette rasoi blue ii vari tipi 4/5 pz",
                            "grammage": null,
                            "price": "1.69",
                            "price_label": "€ 1,69",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Viso",
                            "blueprint": {
                                "top": 76.39751552795032,
                                "left": 40.538004379105416,
                                "width": 19.18894551877679,
                                "height": 19.25465838509317
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/7702018094684_1554883750.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/7702018094714_1554883751.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_pAG4qZx_FkXoBz2.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705450,
                            "field1": "gillette",
                            "field2": "fusion5 power ricambi",
                            "field3": "",
                            "field4": "4 pz",
                            "description": "gillette fusion5 power ricambi 4 pz",
                            "grammage": null,
                            "price": "12.50",
                            "price_label": "€ 12,50",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Viso",
                            "blueprint": {
                                "top": 76.27329192546584,
                                "left": 59.61471232221385,
                                "width": 19.18894551877679,
                                "height": 19.25465838509317
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/7702018482337_1558422858.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_93YBBx8_FPTwJ8t.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705446,
                            "field1": "proraso",
                            "field2": "dopo barba",
                            "field3": "protettivo/lozione/crema vari trattamenti",
                            "field4": "100 ml",
                            "description": "proraso dopo barba protettivo/lozione/crema vari trattamenti 100 ml",
                            "grammage": 100,
                            "price": "2.99",
                            "price_label": "€ 2,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Protettivo",
                                "Lozione",
                                "Crema"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Viso",
                            "blueprint": {
                                "top": 76.5217391304348,
                                "left": 78.95637454230989,
                                "width": 21.04362545769011,
                                "height": 19.25465838509317
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8004395001064_1554992133_1569916721.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8004395001071_1554992434_1569916722.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/8004395001101_ProrasoCremaLiquida_1561998340_1569916722.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_v95Go0h_dZnVqbr.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 10,
                    "image_file": "/media/interactive_flyer_19/pages/page_10.jpg",
                    "products": [
                        {
                            "item_id": 705486,
                            "field1": "chupa chups",
                            "field2": "",
                            "field3": "",
                            "field4": "busta 10 pz",
                            "description": "chupa chups busta 10 pz",
                            "grammage": 0,
                            "price": "1.50",
                            "price_label": "€ 1,50",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 73.04347826086958,
                                "left": 65.1787521389538,
                                "width": 30.581979429244324,
                                "height": 22.98136645962733
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/chupa_cola_1544787672_1569915710.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/chupa_fruit_1544787673_1569915711.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_oYZsk3m_28USC57.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 11,
                    "image_file": "/media/interactive_flyer_19/pages/page_11.jpg",
                    "products": [
                        {
                            "item_id": 705462,
                            "field1": "nivea",
                            "field2": "crema viso antirughe q10",
                            "field3": "vari tipi",
                            "field4": "50 ml",
                            "description": "nivea crema viso antirughe q10 vari tipi 50 ml",
                            "grammage": 50,
                            "price": "7.50",
                            "price_label": "€ 7,50",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Viso",
                            "blueprint": {
                                "top": 3.9751552795031055,
                                "left": 72.7299490331009,
                                "width": 22.89830539660343,
                                "height": 22.98136645962733
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/4005800097812_NiveaCremaViso50Antirughe_1553791335.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/81289a_19919_1569923627.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_5VcreSD_cPtZiN0.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 12,
                    "image_file": "/media/interactive_flyer_19/pages/page_12.jpg",
                    "products": [
                        {
                            "item_id": 705356,
                            "field1": "vileda",
                            "field2": "glitzi crystal fibraverde ",
                            "field3": "2+1 pz",
                            "field4": "",
                            "description": "vileda glitzi crystal fibraverde  2+1 pz",
                            "grammage": 0,
                            "price": "1.39",
                            "price_label": "€ 1,39",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "No Food",
                            "subcategory": "Home",
                            "blueprint": {
                                "top": 0.0,
                                "left": 77.10169460339658,
                                "width": 22.89830539660343,
                                "height": 21.73913043478261
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/Vileda_Abrasivo_Glitzi_Crystal_21x_1542821347_1569918409.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_JqijSjP_ds3QWV0.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705348,
                            "field1": "vileda",
                            "field2": "guanti multiuso color nitrile",
                            "field3": "taglia - s/m - m/l",
                            "field4": "50 pz",
                            "description": "vileda guanti multiuso color nitrile taglia - s/m - m/l 50 pz",
                            "grammage": null,
                            "price": "3.49",
                            "price_label": "€ 3,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Taglia S/M",
                                "Taglia M/L"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Superfici",
                            "blueprint": {
                                "top": 21.73913043478261,
                                "left": 77.10169460339658,
                                "width": 22.89830539660343,
                                "height": 27.080745341614907
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/4023103200791_1557245509.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/4023103200814_1557245510.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_vFkhzfC_BMUUMD4.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705357,
                            "field1": "vileda",
                            "field2": "spugna abrasiva ",
                            "field3": "ondattiva",
                            "field4": "3 pz +1 gratis",
                            "description": "vileda spugna abrasiva  ondattiva 3 pz +1 gratis",
                            "grammage": 0,
                            "price": "1.29",
                            "price_label": "€ 1,29",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Superfici",
                            "blueprint": {
                                "top": 17.267080745341616,
                                "left": 50.20883548915344,
                                "width": 25.547848166479596,
                                "height": 12.04968944099379
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/vileda_ondattiva_colors_31_1539098433_1557242056.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_gBQHNIQ_hxcOPmK.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705352,
                            "field1": "vileda",
                            "field2": "panno multiuso",
                            "field3": "vari tipi",
                            "field4": "",
                            "description": "vileda panno multiuso vari tipi",
                            "grammage": null,
                            "price": "2.49",
                            "price_label": "€ 2,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Superfici",
                            "blueprint": {
                                "top": 28.571428571428573,
                                "left": 50.34131262764725,
                                "width": 25.547848166479596,
                                "height": 19.503105590062113
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/4023103185975_1557245482.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_m3oBkUw_PkxUHVH.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 705341,
                            "field1": "pronto",
                            "field2": "vari tipi",
                            "field3": "",
                            "field4": "",
                            "description": "pronto vari tipi",
                            "grammage": null,
                            "price": "1.99",
                            "price_label": "€ 1,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Superfici",
                            "blueprint": {
                                "top": 0.0,
                                "left": 0.0,
                                "width": 49.6069725958558,
                                "height": 36.866857174346016
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_19/products/5000204670905_1554884573.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/pronto_multiuso_gelsomino_5000204671070_1563461179.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_19/products/cropped_image_s23Chlr_SHh0Dnv.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 13,
                    "image_file": "/media/interactive_flyer_19/pages/fine.png",
                    "products": []
                }





            ]
        }
    }

}

const lefletSwitch = () => {
    return leaflets.bravo;
}

export const serverResponse = lefletSwitch(); 